.CustomerPage{
    
}
.CustomerPage .main .banner_box {
    width: 100%;
    text-align: center;
}
.CustomerPage .main .banner_box .banner1 {
    width: 100%;
    min-height: 32.3vw;
    text-align: center;
    background: url("../../images/customer_banner1.png") no-repeat;
    background-size: 100% 100%;
    /* background-attachment: fixed; */
}
.CustomerPage .main .banner_box .banner1>div{
    text-align-last: left;
    width: 1200px;
    margin: auto;
}
.CustomerPage .main .banner_box .banner_title,
.CustomerPage .main .banner_box .banner_content {
    color: #fff;
}
.CustomerPage .main .banner_box .banner_title {
	font-family: PingFangSC-Semibold;
	font-size: 54px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 172px;
    margin-bottom: 32px;
}
.CustomerPage .main .banner_box .banner_content {
	font-family: PingFangSC-Regular;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 31px;
	letter-spacing: 0px;
	color: #666666;
    width: 530px;
}
.CustomerPage .main .con{
    text-align: center;
    width: 1070px;
    margin: auto;
    margin-top: 82px;
}
.CustomerPage .main .con .ant-col{
    margin-bottom: 41px;
}
.CustomerPage .main .con .item{
	width: 330px;
	height: 267px;
    padding-top: 10px;
	background-color: #ffffff;
	box-shadow: 0px 0px 13px 0px 
		rgba(0, 0, 0, 0.09);
	border-radius: 6px;
}
.CustomerPage .main .con img{
    margin: auto;
}
.CustomerPage .main .con p{
	font-family: PingFangSC-Medium;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 20px;
    margin-bottom: 25px;
}