.RecruitPage .main{
    overflow: hidden;
    width: 1100px;
    margin: auto;
    margin-top: 81px;
    margin-bottom: 190px;
    border-radius: 1px;
    min-height: 22vw;
}
.RecruitPage .main .titleBox{
    height: 100px;
    line-height: 100px;
    background: #E5E2E2;
}
.RecruitPage .main .titleBox .nameBox{
    font-size: 26px;
    font-family: "PingFang SC";
    font-weight: 600;
    color: #333333;
    padding-left: 51px;
}
.RecruitPage .main .titleBox .rightBox .ant-row .ant-col{
    text-align: center;
    font-size: 26px;
    font-family: "PingFang SC";
    font-weight: 600;
    color: #333333;
}
.RecruitPage .main .itemBox {
    background: white;
}
.RecruitPage .main .itemBox .positionBox{
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.RecruitPage .main .itemBox .positionBox:last-child{
    border-bottom: none;
}
.RecruitPage .main .itemBox .positionBox .positiontitle{
    height: 80px;
    line-height: 80px;
    background: #F8F8F8;
    border-radius: 1px;
}
.RecruitPage .main .itemBox .positionBox .positiontitle:hover~.positionmain{
    display: block;
}
.RecruitPage .main .itemBox .positionBox .positiontitle .nameBox{
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #444444;
    padding-left: 51px;
}
.RecruitPage .main .itemBox .positionBox .positiontitle .rightBox .ant-row .ant-col{
    text-align: center;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}
.RecruitPage .main .itemBox .positionBox:hover .positionmain{
    display: block;
}
.RecruitPage .main .itemBox .positionBox .positionmain{
    display: none;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 28px;
    padding-bottom: 29px;
    margin: 41px 49px 1px 49px;
}
.RecruitPage .main .itemBox .positionBox .positionmain .dutytitle{
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #666666;
    line-height: 1em;
    margin: 0;
    margin-bottom: 15px;
}
.RecruitPage .main .itemBox .positionBox .positionmain .dutytitle::before{
    vertical-align: middle;
    display: inline-block;
    content: "";
    width: 4px;
    height: 14px;
    background: #39BCD6;
    border-radius: 2px;
    margin-top: -2px;
    margin-right: 8px;
}
.RecruitPage .main .itemBox .positionBox .positionmain>div{
    margin-bottom: 38px;
}
.RecruitPage .main .itemBox .positionBox .positionmain>div:nth-child(2){
    margin-bottom: 29px;
}
.RecruitPage .main .itemBox .positionBox .positionmain .btnref{
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 41px;
    width: 280px;
    height: 54px;
    line-height: 54px;
    background: #39BCD6;
}