.FooterBox {
  width: 100%;
  background-color: #f7f8fa ;
  color: #333333;
}
.Footer {
  width: 1100px;
  height: 415px;
  padding-top: 81px;
  padding-bottom: 34px;
  background-color: #f7f8fa;
  margin: auto;
  overflow: hidden;
}
.Footer > div {
  flex: 1;
  display: flex;
  line-height: 56px;
  align-items: center;
}
.Footer .Footer_divider{
    background-color: #d1d2d3;
    height: 1px;
}
.Footer >.Footer_bottom_box{
    display: block;
    text-align: center;
    line-height: 30px;
    margin-top: 20px;
}
.Footer .Footer_text_box {
  
}
.Footer .Footer_text_box_center{
    margin-left: 204px;
    margin-top: -100px;
}
.Footer .Footer_text_box_right{
    margin-left: 206px;
    margin-top: -100px;
}
.Footer .Footer_text_box_right a{
  color: #333;
}
.Footer img {
  height: 129px;
  margin-left: 56px;
  margin-bottom: 81px;
  opacity: 1;
}
.Footer .right {
  float: right;
}
