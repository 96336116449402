.SolutionPage{
    
}
.SolutionPage .main .banner_box {
    width: 100%;
    text-align: center;
}
.SolutionPage .main .banner_box .banner1 {
    width: 100%;
    height: 32.3vw;
    text-align: center;
    background: url("../../images/solutbanner1.png") no-repeat;
    background-size: 100% 100%;
    /* background-attachment: fixed; */
}
.SolutionPage .main .banner_box .banner1>div{
    text-align-last: left;
    width: 1200px;
    margin: auto;
}
.SolutionPage .main .banner_box .banner2 {
    width: 100%;
    min-height: 42vw;
    text-align: center;
    background: url("../../images/solutbanner2.png") no-repeat;
    background-size: 100% 100%;
    /* background-attachment: fixed; */
}
.SolutionPage .main .banner_box .banner2 img{
    margin: auto;
}
.SolutionPage .main .banner_box .banner2 p{
	font-family: PingFangSC-Medium;
	font-size: 44px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #ffffff;
    margin-top: 79px;
    margin-bottom: 43px;
}
.SolutionPage .main .banner_box .banner_title,
.SolutionPage .main .banner_box .banner_content {
    color: #fff;
}
.SolutionPage .main .banner_box .banner_title {
	font-family: PingFangSC-Semibold;
	font-size: 54px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 172px;
    margin-bottom: 32px;
}
.SolutionPage .main .banner_box .banner_content {
	font-family: PingFangSC-Regular;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #666666;
    width: 728px;
}
.SolutionPage .main .serveBox{
    background: white;
}
.SolutionPage .main .serveBox>div{
	font-family: PingFangSC-Regular;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
	color: #666666;
    width: 1100px;
    margin: auto;
    background: white;
}
.SolutionPage .main .serveBox>div>p{
    text-align: center;
	font-family: PingFangSC-Medium;
	font-size: 44px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #333333;
    padding-top: 89px;
    margin-top: 0;
    margin-bottom: 0;
}
.SolutionPage .main .serveBox .lastimg{
    text-align: left;
}
.SolutionPage .main .serveBox .dutytitle{
	font-family: PingFangSC-Medium;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #444444;
    margin-top: 163px;
}
.SolutionPage .main .serveBox .dutytitle::before{
    vertical-align: middle;
    display: inline-block;
    content: "";
    width: 4px;
    height: 18px;
    background: #39BCD6;
    border-radius: 2px;
    margin-top: -2px;
    margin-right: 8px;
}
.SolutionPage .main .serveBox .ant-col>div{
    width: 526px;
}
.SolutionPage .main .planBox{
    text-align: center;
    width: 1165px;
    margin: auto;
}
.SolutionPage .main .planBox>p{
	font-family: PingFangSC-Medium;
	font-size: 44px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 80px;
    margin-bottom: 43px;
}
.SolutionPage .main .planBox .ant-col{
    margin-bottom: 31px;
}
.SolutionPage .main .planBox .item{
    width: 330px;
	height: 355px;
    margin: auto;
	background-color: #ffffff;
	box-shadow: 0px 0px 13px 0px 
		rgba(0, 0, 0, 0.09);
	border-radius: 6px;
}
.SolutionPage .main .planBox .item img{
    margin-top: 10px;
}
.SolutionPage .main .planBox .item span{
	font-family: PingFangSC-Medium;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #333333;
    display: inline-block;
    margin: 0;
    margin-top: 15px;
    padding: 0 5px;
    padding-bottom: 10px;
    border-bottom: 4px solid #39bcd6;
}
.SolutionPage .main .planBox .item>div{
	font-family: PingFangSC-Regular;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #666666;
    margin-top: 15px;
}
.SolutionPage .main .planBox .item>div div{
    margin-bottom: 12px;
}
.SolutionPage .main .sulutBox{
    padding-top: 80px;
    background: white;
    margin-top: 50px;
}
.SolutionPage .main .sulutBox>div.widthBox{
    width: 1100px;
    margin: auto;
    margin-top: 43px;
    padding-bottom: 78px;
}
.SolutionPage .main .sulutBox>div.widthBox>div.cardBox{
    width: 100%;
    height: 330px;
	box-shadow: 0px 0px 13px 0px 
		rgba(0, 0, 0, 0.09);
}
.SolutionPage .main .sulutBox p{
    text-align: center;
	font-family: PingFangSC-Medium;
	font-size: 44px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
    margin: 0;
	letter-spacing: 0px;
	color: #333333;
}
.SolutionPage .main .sulutBox>div.widthBox>div.cardBox .leftBox{
    vertical-align: top;
    display: inline-block;
}
.SolutionPage .main .sulutBox>div.widthBox>div.cardBox .leftBox div{
    cursor: pointer;
    text-align: center;
	font-family: PingFangSC-Regular;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #333333;
    width: 210px;
    height: 110px;
    line-height: 110px;
    border-right: 1px solid #f7f7f7;
}
.SolutionPage .main .sulutBox>div.widthBox>div.cardBox .leftBox div:nth-child(2){
    border-top: 1px solid #f7f7f7;
    border-bottom: 1px solid #f7f7f7;
}
.SolutionPage .main .sulutBox>div.widthBox>div.cardBox .leftBox .checked{
    color: white;
    background: #39bcd6;
}
.SolutionPage .main .sulutBox>div.widthBox>div.cardBox .con{
    display: inline-block;
    width: calc(100% - 210px);
    height: 330px;
    background-size: auto 330px;
}
.SolutionPage .main .sulutBox .widthBox .cardBox .con>div{
    margin-left: 50px;
}
.SolutionPage .main .sulutBox .widthBox .cardBox .con>div div{
    width: 330px;
	font-family: PingFangSC-Regular;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
	color: #666666;
}
.SolutionPage .main .sulutBox .widthBox .cardBox .con>div div div{
    padding-left: 14px;
}
.SolutionPage .main .sulutBox .widthBox .cardBox .con>div div div::before{
    content: "";
    display: inline-block;
	width: 10px;
	height: 10px;
    border-radius: 50%;
	background-color: #4a62ff;
    margin-left: -17px;
    margin-right: 7px;
}
.SolutionPage .main .sulutBox .widthBox .cardBox .con .dutytitle{
    text-align: left;
	font-family: PingFangSC-Medium;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #444444;
    margin-top: 57px;
    margin-bottom: 18px;
}
.SolutionPage .main .sulutBox .widthBox .cardBox .con .dutytitle::before{
    vertical-align: middle;
    display: inline-block;
    content: "";
    width: 4px;
    height: 18px;
    background: #39BCD6;
    border-radius: 2px;
    margin-top: -2px;
    margin-right: 8px;
}