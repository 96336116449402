.AboutUsPage{
    
}

.AboutUsPage .main .banner_box {
    width: 100%;
    text-align: center;
}
.AboutUsPage .main .banner_box .banner1 {
    width: 100%;
    min-height: 32.3vw;
    text-align: center;
    background: url("../../images/index_banner_1.png") no-repeat;
    background-size: 100% 100%;
    /* background-attachment: fixed; */
}
.AboutUsPage .main .banner_box .banner_title,
.AboutUsPage .main .banner_box .banner_content {
    color: #fff;
}
.AboutUsPage .main .banner_box .banner_title {
    font-family: PingFangSC-Semibold;
    font-size: 60px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 91px;
    letter-spacing: 0px;
    margin-top: 11vw;
}
.AboutUsPage .main .banner_box .banner_content {
    font-family: PingFangSC-Regular;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 39px;
    letter-spacing: 0px;
}
.AboutUsPage .main .middleBox{
	font-family: PingFangSC-Regular;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
	color: #666666;
    background: #f9fafb;
}
.AboutUsPage .main .middleBox img{
	box-shadow: 0px 0px 13px 3px 
		rgba(0, 0, 0, 0.09);
}
.AboutUsPage .main .middleBox>div{
    width: 1126px;
    margin: auto;
    padding-top: 1px;
    padding-bottom: 68px;
}
.AboutUsPage .main .middleBox .title{
    text-align: center;
	font-family: PingFangSC-Medium;
	font-size: 44px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 56px;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 79px;
    margin-bottom: 34px;
}
.AboutUsPage .main .middleBox .dutytitle{
	font-family: PingFangSC-Medium;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #444444;
    margin-bottom: 16px;
    margin-top: 102px;
}
.AboutUsPage .main .middleBox .dutytitle::before{
    vertical-align: middle;
    display: inline-block;
    content: "";
    width: 4px;
    height: 14px;
    background: #39BCD6;
    border-radius: 2px;
    margin-top: -2px;
    margin-right: 8px;
}
.AboutUsPage .main .middleBox .con{
    margin-right: 47px;
}
.AboutUsPage .main .botBox{
    padding-top: 1px;
    margin-bottom: 83px;
    background: white;
}
.AboutUsPage .main .botBox>div{
    width: 1144px;
    margin: auto;
}
.AboutUsPage .main .botBox p{
    text-align: center;
	font-family: PingFangSC-Medium;
	font-size: 44px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 56px;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 83px;
    margin-bottom: 40px;
}
.AboutUsPage .main .botBox .item{
    text-align: center;
    width: 100%;
    height: 565px;
}
.AboutUsPage .main .botBox .item img{
    display: inline-block;
}
.AboutUsPage .main .botBox .item .img1{
    margin-right: 20px;
}
.ant-carousel ul.dotbox li.slick-active button{
    background: #1694bf;
}
.ant-carousel ul.dotbox li button{
    background: #c1c1c1;
}