.HeaderBox {
  width: 100%;
  background-color: #fff;
}
.HeaderBox .Header {
  width: 1200px;
  height: 80px;
  background-color: #fff;
  margin-left: calc(50% - 600px);
  overflow: hidden;
  display: flex;
}
.HeaderBox .Header > div {
  flex: 1;
  display: flex;
  line-height: 80px;
  align-items: center;
}
.HeaderBox .Header img {
  height: 35px;
}
.HeaderBox .Header .right {
  float: right;
}
.HeaderBox .Header .menuBox {
  line-height: 80px;
}
.HeaderBox .Header .menuBox > .ant-menu-item-selected,.HeaderBox .Header .menuBox > .ant-menu-item-active {
  color: #1694bf;
  /* background: url('../../images.HeaderBox /header_bg.png'); */
  background-repeat:no-repeat;
  background-position:bottom;
  background-attachment:fixed;
  background-size: 100%;
  border-radius: 0px;
}
.HeaderBox .Header .menuBox > .ant-menu-item-selected::after,.HeaderBox .Header .menuBox > .ant-menu-item-active::after {
  border-width: 4px;
  border-bottom-color: #1694bf !important;
}
.HeaderBox .Header .right ul.ant-menu li.ant-menu-item{
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}
.HeaderBox .Header .right ul.ant-menu li.ant-menu-item span.ant-menu-title-content a{
	font-family: PingFangSC-Regular;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #3b3b3b;
} 
.HeaderBox .Header .right ul.ant-menu li.ant-menu-item-selected span.ant-menu-title-content a,.HeaderBox .Header .right ul.ant-menu li.ant-menu-item:hover span.ant-menu-title-content a{
  color: #1694bf;
}
.HeaderBox .Header .right ul.ant-menu li.ant-menu-item:hover span.ant-menu-title-content::after,.HeaderBox .Header .right ul.ant-menu li.ant-menu-item-selected span.ant-menu-title-content::after{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    content: "";
    width: 0;
    height: 0;
    margin: auto;
    border-left: 8px solid transparent;  
    border-right: 8px solid transparent;  
    border-bottom: 14px solid #1694bf; 
}

