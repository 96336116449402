.ProductservicePage{
    
}
.ProductservicePage .main .banner_box {
    width: 100%;
    text-align: center;
}
.ProductservicePage .main .banner_box .banner1 {
    width: 100%;
    min-height: 32.3vw;
    text-align: center;
    background: url("../../images/productbanner1.png") no-repeat;
    background-size: 100% 100%;
    /* background-attachment: fixed; */
}
.ProductservicePage .main .banner_box .banner1>div{
    text-align-last: left;
    width: 1200px;
    margin: auto;
}
.ProductservicePage .main .banner_box .banner2 {
    width: 100%;
    min-height: 35vw;
    text-align: center;
    background: url("../../images/productbanner2.png") no-repeat;
    background-size: 100% 100%;
    /* background-attachment: fixed; */
}
.ProductservicePage .main .banner_box .banner2 p{
	font-family: PingFangSC-Medium;
	font-size: 44px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 79px;
}
.ProductservicePage .main .banner_box .banner2 .prointr{
    vertical-align: top;
    text-align: left;
    display: inline-block;
	font-family: PingFangSC-Regular;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #666666;
    width: 454px;
}
.ProductservicePage .main .banner_box .banner2 img{
    display: inline-block;
}
.ProductservicePage .main .banner_box .banner2 .prointr p{
	font-family: PingFangSC-Semibold;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 47px;
    margin-bottom: 31px;
}
.ProductservicePage .main .banner_box .banner2 .prointr img{
    vertical-align: middle;
    margin-bottom: 41px;
}
.ProductservicePage .main .banner_box .banner_title,
.ProductservicePage .main .banner_box .banner_content {
    color: #fff;
}
.ProductservicePage .main .banner_box .banner_title {
	font-family: PingFangSC-Semibold;
	font-size: 54px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 172px;
    margin-bottom: 32px;
}
.ProductservicePage .main .banner_box .banner_content {
	font-family: PingFangSC-Regular;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 38px;
	letter-spacing: 0px;
	color: #666666;
    width: 600px;
}
.ProductservicePage .main .fuproduce{
	padding-bottom: 84px;
    background: white;
}

.ProductservicePage .main .fuproduce>div{
    width: 1200px;
    margin: auto;
}
.ProductservicePage .main .fuproduce>div>p.title{
    text-align: center;
	font-family: PingFangSC-Medium;
	font-size: 44px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 56px;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 80px;
    padding-bottom: 39px;
}
.ProductservicePage .main .fuproduce>div .line{
	width: 100%;
	height: 1px;
	margin: auto;
	background: #e1e1e1;
}
.ProductservicePage .main .fuproduce>div .menuBox{
	width: 921px;
	margin: auto;
	margin-bottom: 31px;
}
.ProductservicePage .main .fuproduce>div .menuBox .item{
	font-family: PingFangSC-Medium;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 40px;
	letter-spacing: 0px;
	color: #444444;
	text-align: center;
	display: inline-block;
	width: 25%;
	margin: auto;
}
.ProductservicePage .main .fuproduce>div .menuBox .item img{
	cursor: pointer;
	vertical-align: middle;
}
.ProductservicePage .main .fuproduce>div .menuBox .item p{
	cursor: pointer;
	margin-top: 20px;
	margin-bottom: 0;
	padding-bottom: 30px;
	border-bottom: 4px solid white;
}
.ProductservicePage .main .fuproduce>div .menuBox .checkenitem p{
	border-bottom: 4px solid #1694bf;
}
.ProductservicePage .main .fuproduce>div .itemCon{
	width: 921px;
	margin: auto;
}
.ProductservicePage .main .fuproduce>div .itemConLonger{
	width: 1000px;
	margin: auto;
}
.ProductservicePage .main .fuproduce>div .itemCon img{
	vertical-align: middle;
}
.ProductservicePage .main .fuproduce>div .itemCon .rowItem{
	vertical-align: top;
	margin-top: 30px;
}
.ProductservicePage .main .fuproduce>div .itemCon .rowItem>div{
	font-family: PingFangSC-Regular;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
	color: #666666;
	vertical-align: top;
	display: inline-block;
	width: 300px;
	height: 140px;
	margin-left: 30px;
	padding-left: 24px;
	padding-right: 23px;
	background-color: #f7f7f7;
	box-shadow: 0px 0px 10px 0px 
		rgba(0, 0, 0, 0.1);
	border: solid 2px #ffffff;
}
.ProductservicePage .main .fuproduce>div .itemCon .rowItem .longer{
	font-family: PingFangSC-Regular;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
	color: #666666;
	vertical-align: top;
	display: inline-block;
	width: 350px;
	height: 140px;
	margin-left: 20px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #f7f7f7;
	box-shadow: 0px 0px 10px 0px 
		rgba(0, 0, 0, 0.1);
	border: solid 2px #ffffff;
}
.ProductservicePage .main .fuproduce>div .itemCon .rowItem>div img{
	display: block;
	margin-top: 26px;
	margin-bottom: 12px;
}
.ProductservicePage .main .fuproduce>div .itemCon .rowItem .titleBox{
	font-family: PingFangSC-Medium;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #ffffff;
	vertical-align: top;
	text-align: center;
	width: 260px;
	height: 140px;
	line-height: 140px;
	margin-left: 0;
	white-space: break-spaces;
}
.ProductservicePage .main .fuproduce>div .itemConLonger .rowItem .titleBox{
	font-family: PingFangSC-Medium;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #ffffff;
	vertical-align: top;
	text-align: center;
	width: 260px;
	height: 140px;
	line-height: 140px;
	margin-left: 0;
	white-space: break-spaces;
	padding: 0px 10px 0px 10px;
}
.ProductservicePage .main .fuproduce>div .itemConLonger .rowItem .titleBox .title{
	display: flex;
	line-height: 40px;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.ProductservicePage .main .advBox {
    width: 100%;
    text-align: center;
}
.ProductservicePage .main .advBox .banner1 {
    width: 100%;
    min-height: 46vw;
    text-align: center;
    background: url("../../images/advbg.png") no-repeat;
    background-size: 100% 100%;
    /* background-attachment: fixed; */
}
.ProductservicePage .main .advBox .banner_title,
.ProductservicePage .main .advBox .banner_content {
    color: #fff;
}
.ProductservicePage .main .advBox .banner_title {
    font-family: PingFangSC-Semibold;
    font-size: 60px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 91px;
    letter-spacing: 0px;
    margin-top: 11vw;
}
.ProductservicePage .main .advBox .banner_content {
    font-family: PingFangSC-Regular;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 39px;
    letter-spacing: 0px;
}
.ProductservicePage .main .advBox .banner1 .advcon{
	width: 1200px;
	margin: auto;
}
.ProductservicePage .main .advBox .banner1 .advcon>p{
	font-family: PingFangSC-Medium;
	font-size: 44px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 56px;
	letter-spacing: 0px;
	color: #333333;
	margin-top: 80px;
	margin-bottom: 41px;
}
.ProductservicePage .main .advBox .banner1 .advcon .item{
	text-align: left;
	width: 264px;
	padding: 32px 24px 36px 30px;
	background-color: #ffffff;
	box-shadow: 0px 0px 7px 0px 
		rgba(0, 0, 0, 0.1);
		margin-bottom: 30px;
}
.ProductservicePage .main .advBox .banner1 .advcon .item p {
	font-family: PingFangSC-Medium;
	font-size: 22px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #424749;
	line-height: 1em;
	margin-top: 10px;
	margin-bottom: 20px;
}
.ProductservicePage .main .advBox .banner1 .advcon .item1{
	margin-top: 30px;
}
.ProductservicePage .main .successBox .banner1 {
    width: 100%;
    min-height: 30.5vw;
    text-align: center;
    background: url("../../images/successbg.png") no-repeat;
    background-size: 100% 100%;
    /* background-attachment: fixed; */
}
.ProductservicePage .main .successBox .banner_title,
.ProductservicePage .main .successBox .banner_content {
    color: #fff;
}
.ProductservicePage .main .successBox .banner_title {
    font-family: PingFangSC-Semibold;
    font-size: 60px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 91px;
    letter-spacing: 0px;
    margin-top: 11vw;
}
.ProductservicePage .main .successBox .banner_content {
    font-family: PingFangSC-Regular;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 39px;
    letter-spacing: 0px;
}
.ProductservicePage .main .successBox .succecon{
	text-align: center;
	padding-bottom: 20px;
}
.ProductservicePage .main .successBox .succecon p{
	font-family: PingFangSC-Medium;
	font-size: 44px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #333333;
	margin-top: 72px;
	margin-bottom: 50px;
}
.ProductservicePage .main .successBox .succecon img{
	margin: auto;
}