.RecruitUpBox{
    min-height: calc(100vh - 575px);
}
.RecruitUp{
    width: 1100px;
    /* height: 493px; */
    margin: auto;
    margin-top: 81px;
    /* margin-bottom: 527px; */
    background: #FFFFFF;
    border-radius: 1px;
}
.RecruitUp .title{
    padding-left: 49px;
    padding-top: 28px;
    padding-bottom: 29px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.RecruitUp .title .titlename{
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 1em;
}
.RecruitUp .title .titlecon{
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 1em;
    margin-top: 12px;
}
.RecruitUp .upBox{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 28px;
    padding-left: 49px;
    padding-top: 29px;
    padding-bottom: 40px;
}
.RecruitUp .upBox>div{
    margin-top: 18px;
}
.RecruitUp .upBox .dutytitle{
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #666666;
    line-height: 1em;
    margin: 0;
    margin-bottom: 15px;
}
.RecruitUp .upBox .dutytitle::before{
    vertical-align: middle;
    display: inline-block;
    content: "";
    width: 4px;
    height: 14px;
    background: #39BCD6;
    border-radius: 2px;
    margin-top: -2px;
    margin-right: 8px;
}
.RecruitUp .upBox .btnref{
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 41px;
    width: 280px;
    height: 54px;
    line-height: 54px;
    background: #1694BF;
}
.RecruitUp .upBox .btnref1{
    color: #39BCD6;
    background: #D6F8FF;
}
.RecruitUp .upBox .btnref2{
    background: #39BCD6;
    margin-bottom: 20px;
}

.RecruitUp .upBox>.btnref{
    margin: auto;
}
.RecruitUp .upBox>div>div{
    margin-bottom: 13px;
}
.RecruitUp .upBox .submit{
    border-radius: 0;
    line-height: normal;
    margin-left: 410px;
}