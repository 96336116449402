.HomePage {
  width: 100vw;
  background-color: #FFF;
}
.HomePage .banner_box {
  width: 100%;
  text-align: center;
}
.HomePage .banner_box .banner1 {
  width: 100%;
  min-height: 32.3vw;
  text-align: center;
  background: url("../../images/index_banner_1.png") no-repeat;
  background-size: 100% 100%;
  /* background-attachment: fixed; */
}
.HomePage .banner_box .banner2 {
    width: 100%;
    min-height: 32.3vw;
    text-align: center;
    background: url("../../images/productbanner1.png") no-repeat;
    background-size: 100% 100%;
    /* background-attachment: fixed; */
}
.HomePage .banner_box .banner3 {
    width: 100%;
    min-height: 32.3vw;
    text-align: center;
    background: url("../../images/solutbanner1.png") no-repeat;
    background-size: 100% 100%;
    /* background-attachment: fixed; */
}
.HomePage .banner_box .banner2>div{
    text-align-last: left;
    width: 1200px;
    margin: auto;
}
.HomePage .banner_box .banner_title,
.HomePage .banner_box .banner_content {
  color: #fff;
}
.HomePage .banner_box .banner_title {
  font-family: PingFangSC-Semibold;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 91px;
  letter-spacing: 0px;
  margin-top: 11vw;
}
.HomePage .banner_box .banner_content {
  font-family: PingFangSC-Regular;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 39px;
  letter-spacing: 0px;
}
.HomePage .banner_box .banner2 .banner_title {
	font-family: PingFangSC-Semibold;
	font-size: 54px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 172px;
    margin-bottom: 32px;
}
.HomePage .banner_box .banner2 .banner_content {
	font-family: PingFangSC-Regular;
	font-size: 26px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 38px;
	letter-spacing: 0px;
	color: #666666;
    width: 600px;
}
.HomePage .product_box{
    width: 100vw;
    min-height: 36vw;
    background: url("../../images/index_product_bg.png") no-repeat;
    background-size: 100% 100%;
}
.HomePage .product_box .product{
    width: 1200px;
    font-weight: normal;
	font-stretch: normal;
    margin-left: calc(50% - 600px);
}
.HomePage .product_box .product .product_title{
    text-align: center;
    font-family: PingFangSC-Medium;
	font-size: 44px;
	line-height: 56px;
	letter-spacing: 0px;
	color: #333333;
    padding-top: 80px;
    padding-bottom: 40px;
}
.HomePage .product_box .product .product_subtitle{
    width: 100%;
    height: 140px;
    background: url("../../images/index_product_bg_sm.png") no-repeat;
    background-size: 100% 100%;
    color: #FEFFFF;
    position: relative;
    padding: 32px 28px;
}
.HomePage .product_box .product .product_subtitle .product_subtitle_title{
    font-family: PingFangSC-Medium;
	font-size: 26px;
    margin-bottom: 14px;
}
.HomePage .product_box .product .product_subtitle .product_subtitle_content{
    font-family: PingFangSC-Regular;
	font-size: 16px;
	letter-spacing: 0px;
	color: #feffff;
	opacity: 0.8;
}
.HomePage .product_box .product .product_subtitle .product_subtitle_btn{
    color: white;
    cursor: pointer;
    position: absolute;
    font-family: PingFangSC-Regular;
	font-size: 20px;
	line-height: 56px;
    border: 1px solid #FFF;
    padding: 0px 56px;
    top: 42px;
    right: 26px;
}
.HomePage .product_box .product .product_subtitle .product_subtitle_btn:hover{
	box-shadow: 0px 0px 13px 3px 
		rgba(0, 0, 0, 0.09);
}
.HomePage .product_box .product .product_content{
    display: flex;
    padding-bottom: 80px;
}
.HomePage .product_box .product .product_content .modular{
    flex: 1;
    padding: 40px 32px 40px 28px;
    border-right: 1px solid #CCC;
    background-color: #FFF;
}
.HomePage .product_box .product .product_content .modular:last-child{
    border-right: 0px solid #CCC;
}
.HomePage .product_box .product .product_content .modular .modular_title{
    font-family: PingFangSC-Medium;
	font-size: 20px;
	letter-spacing: 0px;
	color: #444444;
    margin-bottom: 18px;
}
.HomePage .product_box .product .product_content .modular .modular_content{
    font-family: PingFangSC-Regular;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0px;
	color: #666666;
}

.HomePage .solution_box{
    width: 100vw;
    min-height: 36vw;
    /* background: url("../../images/index_product_bg.png") no-repeat;
    background-size: 100% 100%; */
}
.HomePage .solution_box .solution{
    width: 1200px;
    height: 500px;
    font-weight: normal;
	font-stretch: normal;
    margin-left: calc(50% - 600px);
}
.HomePage .solution_box .solution .solution_title{
    text-align: center;
    font-family: PingFangSC-Medium;
	font-size: 44px;
	line-height: 56px;
	letter-spacing: 0px;
	color: #333333;
    padding-top: 80px;
    padding-bottom: 40px;
}
.HomePage .solution_box .solution .solution_subtitle{
    width: 100%;
    /* height: 140px; */
    text-align: center;
    background-size: 100% 100%;
    position: relative;
    padding: 0px 28px 40px 28px;
}
.HomePage .solution_box .solution .solution_content{
    display: flex;
    padding-bottom: 80px;
}
.HomePage .solution_box .solution .solution_content .modular{
    flex: 1;
}
.HomePage .solution_box .solution .solution_content .first,.HomePage .solution_box .solution .solution_content .second,
.HomePage .solution_box .solution .solution_content .third,.HomePage .solution_box .solution .solution_content .fourth{
    flex: 1;
}
.HomePage .solution_box .solution .solution_content .first>div{
    width: 100%;
    height: 100%;
    background-color: #fbfbfb;
}
.HomePage .solution_box .solution .solution_content .second>div{
    background-color: #f4f4f4;
}
.HomePage .solution_box .solution .solution_content .third>div{
    background-color: #fbfbfb;
}
.HomePage .solution_box .solution .solution_content .fourth>div{
    background-color: #f4f4f4;
}
.HomePage .solution_box .solution .solution_content .modular .modular_title{
    font-family: PingFangSC-Medium;
	font-size: 20px;
	letter-spacing: 0px;
	color: #424749;
    margin-bottom: 18px;
}
.HomePage .solution_box .solution .solution_content .modular>div{
	font-family: PingFangSC-Regular;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
	color: #666666;
    width: 316px;
    height: 243px;
    padding: 40px 32px 40px 28px;
}
.HomePage .solution_box .solution .solution_content .first:hover{
    width: 316px;
    height: 296px;
    margin-top: -24px;
    background: url('../../images/yybg.png') no-repeat;
}
.HomePage .solution_box .solution .solution_content .modular:hover div{
    background: none;
}
.HomePage .solution_box .solution .solution_content .second:hover{
    width: 316px;
    height: 296px;
    margin-top: -24px;
    background: url('../../images/huobifenxi.png') no-repeat;
}
.HomePage .solution_box .solution .solution_content .third:hover{
    width: 316px;
    height: 296px;
    margin-top: -24px;
    background: url('../../images/zhaohuishenqingliucheng.png') no-repeat;
}
.HomePage .solution_box .solution .solution_content .fourth:hover{
    width: 316px;
    height: 296px;
    margin-top: -24px;
    background: url('../../images/tishengxiaoliang.png') no-repeat;
}
.HomePage .solution_box .solution .solution_content .modular>div img{
    vertical-align: middle;
    width: 24px;
    margin-bottom: 11px;
}
.HomePage .solution_box .solution .solution_content .modular:hover .modular_content{
	font-family: PingFangSC-Regular;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
	color: #7cadb8;
}
.HomePage .solution_box .solution .solution_content .modular:hover img{
    margin-top: -10px;
}
.HomePage .solution_box .solution .solution_content .modular .modular_content{
    font-family: PingFangSC-Regular;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0px;
	color: #666666;
}
.HomePage .successBox{
    background: none;
}
.HomePage .successBox .banner1 {
    width: 100%;
    height: calc(30.5vw + 80px);
    text-align: center;
    background: url("../../images/successbg.png") no-repeat;
    background-size: 100% 100%;
    /* background-attachment: fixed; */
}
.HomePage .successBox .banner_title,
.HomePage .successBox .banner_content {
    color: #fff;
}
.HomePage .successBox .banner_title {
    font-family: PingFangSC-Semibold;
    font-size: 60px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 91px;
    letter-spacing: 0px;
    margin-top: 11vw;
}
.HomePage .successBox .banner_content {
    font-family: PingFangSC-Regular;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 39px;
    letter-spacing: 0px;
}
.HomePage .successBox .succecon{
	text-align: center;
}
.HomePage .successBox .succecon p{
	font-family: PingFangSC-Medium;
	font-size: 44px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1em;
	letter-spacing: 0px;
	color: #333333;
	margin-top: 72px;
	margin-bottom: 50px;
}
.HomePage .successBox .succecon img{
	margin: auto;
}
.ant-carousel ul.dotbox li.slick-active button{
    background: #1694bf;
}
.ant-carousel ul.dotbox li button{
    background: #c1c1c1;
}